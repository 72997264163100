import portfolio from '../assets/svg/projects/portfolio.png'
import resume from '../assets/svg/projects/resume.png'
import angularjs from '../assets/svg/projects/angularjs.png'



export const projectsData = [
    {
        id: 1,
        projectName: 'Developer Portfolio',
        projectDesc: 'This applications is build with modern react js , bootstrap technologies',
        tags: ['React', 'CSS', 'Material Ui'],
        //code: 'https://github.com/alkaria999/developer-portfolio',
        //demo: 'https://tanim-mandal.netlify.app/',
        image: portfolio
    },
    {
        id: 2,
        projectName: 'Angular JS Demo',
        projectDesc: 'Angular JS Demo applications used for hands on CRUD Application training.',
        tags: ['AngularJS', 'CSS', 'HTML'],
        //code: 'https://github.com/alkaria999/alngularjs-demo',
        //demo: 'https://alkaria999.github.io/alngularjs-demo/',
        image: angularjs
    },
    {
        id: 3,
        projectName: 'My Resume',
        projectDesc: 'This project is a used to create personal resume.',
        tags: ['HTML', 'CSS'],
        //code: 'https://github.com/alkaria999/my-resume',
        //demo: 'https://alkaria999.netlify.app',
        image: resume
    },
    {
        id: 4,
        projectName: 'Developer Portfolio',
        projectDesc: 'This applications is build with modern react js , bootstrap technologies',
        tags: ['React', 'CSS', 'Material Ui'],
        //code: 'https://github.com/alkaria999/developer-portfolio',
        //demo: 'https://tanim-mandal.netlify.app/',
        image: portfolio
    }
]
