export const experienceData = [
    {
        id: 1,
        company: 'Cognizant Technology & Solutions',
        jobtitle: 'Full Stack Engineer',
        startYear: '2023',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Tata Consultancy Services',
        jobtitle: 'Full Stack Developer',
        startYear: '2023',
        endYear: '2018'
    },
    {
        id: 3,
        company: 'Wipro Technologies',
        jobtitle: 'Software Engineer',
        startYear: '2018',
        endYear: '2016'
    },
]