import { BiPencil } from "react-icons/bi";
import { BsCodeSlash} from "react-icons/bs";
import { AiOutlineRobot } from "react-icons/ai";
import { FaCodepen,FaCloud, FaTabletAlt, } from "react-icons/fa";

 

export const servicesData = [
    {
        id: 2,
        title: 'Application Development',
        icon: <BiPencil /> 
    },
    {
        id: 3,
        title: 'API Implementation',
        icon: <BsCodeSlash />
    },

    {
        id: 6,
        title: 'Web Design',
        icon: <AiOutlineRobot />
    },
    {
        id: 7,
        title: 'Exploring Cloud Technologies',
        icon: <FaCloud />
    },
    {
        id: 9,
        title: 'DevOps Engineer',
        icon: <FaCodepen />
    },
    {
        id: 11,
        title: 'Countinous Learning',
        icon: <FaTabletAlt />
    }
]